import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./gallery.css";
import GalleryImg1 from "../../../assets/IMMAGINI/gallery1.jpg";
import GalleryImg2 from "../../../assets/IMMAGINI/gallery2.jpg";
import GalleryImg3 from "../../../assets/IMMAGINI/gallery3.jpg";
import GalleryImg4 from "../../../assets/IMMAGINI/gallery4.jpg";
import GalleryImg6 from "../../../assets/IMMAGINI/gallery6.jpg";
import {Modal, Carousel} from "antd";
import {Helmet} from "react-helmet";

export default function Gallery() {
    const [isOpen, setOpenState] = useState(false);
    const [actualSlide, setActualSlide] = useState(0);

    const showModal = (actual) => {
        setOpenState(!isOpen);
        setActualSlide(actual);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Galleria</title>
                <meta name="description" content="Incalmo galleria"/>
            </Helmet>

            <div className="galleryConatiner">
                <Container fluid>

                    <img
                        onClick={() => showModal(0)}
                        src={GalleryImg1}
                        alt=""
                        className="galleryImg"
                    />

                    <Row className="justify-content-center">
                        <Col lg={4} md={3} sm={12}>
                            <img
                                onClick={() => showModal(1)}
                                src={GalleryImg2}
                                alt=""
                                className="galleryImg"
                            />
                        </Col>
                        <Col lg={4} md={3} sm={12}>
                            <img
                                onClick={() => showModal(2)}
                                src={GalleryImg3}
                                alt=""
                                className="galleryImg"
                            />
                        </Col>
                        <Col lg={4} md={3} sm={12}>
                            <img
                                onClick={() => showModal(3)}
                                src={GalleryImg4}
                                alt=""
                                className="galleryImg"
                            />
                        </Col>
                    </Row>

                    <img
                        onClick={() => showModal(4)}
                        src={GalleryImg6}
                        alt=""
                        className="galleryImg"
                    />
                </Container>
            </div>

            <div className="menuGreenSection">
                <div className="galleryGreenCard">
                    <p className="galleryGreenCardText">
                        <em>Guida Michelin - Nuovi Ristoranti 2022</em>

                    </p>
                    <a
                        style={{textDecoration: "underline", color: "white"}}
                        href="https://guide.michelin.com/it/it/veneto/este/ristorante/incalmo"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <p className="galleryGreenCardText">LEGGI L’ARTICOLO</p>
                    </a>
                </div>
                <div className="galleryGreenCard">
                    <p className="galleryGreenCardText">
                        <em>Identità Golose - Novità Guida 2022</em>
                    </p>
                    <a
                        style={{textDecoration: "underline", color: "white"}}
                        href="https://www.identitagolose.it/sito/it/62/29885/ristoranti/incalmo.html?p=0&hash-city=cs-city-este&"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <p className="galleryGreenCardText">LEGGI L’ARTICOLO</p>
                    </a>
                </div>
                <div className="galleryGreenCard">
                    <p className="galleryGreenCardText">
                        <em> Gli Intrecci di Incàlmo, estense contemporaneo </em>

                    </p>
                    <a
                        style={{textDecoration: "underline", color: "white"}}
                        href="https://www.stefanocaffarri.it/frammenti/frammenti-di-cibo/gli-intrecci-di-inc%C3%A0lmo,-estense-contemporaneo/237/5-5624988"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <p className="galleryGreenCardText">LEGGI L’ARTICOLO</p>
                    </a>
                </div>
            </div>
            <Modal
                maskClosable={true}
                centered
                closable={false}
                visible={isOpen}
                onOk={showModal}
                width="80%"
                onCancel={showModal}
                closeIcon={false}
                destroyOnClose
                footer={[
                    <button
                        className="closeModalButtonCustom"
                        key="close"
                        type="primary"
                        onClick={showModal}
                    >
                        Chiudi
                    </button>,
                ]}
            >
                <Carousel
                    className="menuCarousel"
                    style={{
                        width: "100%",
                        overflow: "hidden",
                        height: "auto",
                        padding: "0 4%",
                    }}
                    effect="scrollx"
                    pauseOnHover={false}
                    speed={1000}
                    dots={true}
                    initialSlide={actualSlide}
                >
                    <div>
                        <img
                            src={GalleryImg1}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={GalleryImg2}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={GalleryImg3}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={GalleryImg4}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={GalleryImg6}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                </Carousel>
            </Modal>
        </React.Fragment>
    );
}
