import React from "react";
import "./homeBodySection.css";
import { Link } from "react-router-dom";
import FoodPDF from "../../../assets/PDFMENU/Food.pdf";
import AfterPDF from "../../../assets/PDFMENU/Dolci.pdf";
import VinePDF from "../../../assets/PDFMENU/Vini.pdf";

export default function HomeBodySection() {
  return (
    <div className="sectionDue">
      <div className="whiteContainer">
        <h2 className='py-3 giorgio'>INCÀLMO IN DIALETTO VENETO SIGNIFICA INNESTO</h2>
        <p className="whiteSub py-2">
          Come due piante che si uniscono per dare luce ad una più forte e
          resistente, alla nostra tavola germogliano nuove idee, nuove
          realizzazioni, che si contaminano con le nostre esperienze passate, si
          incontrano, si scontrano e si fondono.
        </p>
        <div className="ballsContainer">
          <Link to="/gallery">
            <div className="whiteBall">
              <p className="ballText">RISTORANTE</p>
            </div>{" "}
          </Link>

          <Link to="/squadra">
            <div className="whiteBall">
              <p className="ballText">SQUADRA</p>
            </div>{" "}
          </Link>
        </div>
      </div>
      <div className="transparentContainer"/>
      <div className="greenContainer">
        <h2 className='py-3 giorgio' style={{ color: "white" }}>LE GEMME, I TRALCI, I SEMI</h2>
        <p className="greenSub py-2">
          Un percorso equilibrato, ricco e plurale, il cui il filo conduttore è
          l’amore per il buon cibo e il rispetto per la materia prima.
        </p>
        <div className="ballsContainer">
          <a href={FoodPDF} target="_blank" rel="noopener noreferrer">
            <div className="greenBall">
              <p className="greenballText">CUCINA</p>
            </div>{" "}
          </a>

          <a href={VinePDF} target="_blank" rel="noopener noreferrer">
            <div className="greenBall">
              <p className="greenballText">
                CANTINA <br />
              </p>
            </div>
          </a>

          <a href={AfterPDF} target="_blank" rel="noopener noreferrer">
            <div className="greenBall">
              <p className="greenballText">
                DESSERT <br />
              </p>
            </div>
          </a>
        </div>
      </div>
      <div className="linkContainer">
        <h2 className='py-5 giorgio'>OSPITALITÀ</h2>
        <p className="whiteSub">
          Scopri il{" "}
          <a
            style={{ textDecoration: "underline", color: "black" }}
            href="https://www.hotel-beatrice.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Beatrice
          </a>{" "}
          : storico albergo di Este con i suoi arredi originali anni ’50 e
          l’ospitalità giovane e internazionale.
        </p>
      </div>
    </div>
  );
}
