import React from "react";
import "./transparentSection.css";

export default function TransparentSection() {
  return (
    <div className="sectionUno">
      <div className="scrollContainer">
        <i className="fas fa-chevron-down scrollIcon"></i>
      </div>
    </div>
  );
}
