import React from "react";
import "./footer.css";
import IncalmoImgFooter from "../../../assets/PNGLOGO/incalmo_completo_footer.png";
import IncalmoImgPartner from "../../../assets/PNGLOGO/perrier_footer.png";
import IncalmoImgPartner2 from "../../../assets/PNGLOGO/LOGO_AUTOSERENISSIMA_PDF_VETT.png";


export default function Footer() {
  return (
    <div className="footer">
      <div className="footerUI">
        <img className="footerLogo" src={IncalmoImgFooter} alt="logo-footer" />

        <div className="footerUIContainer">
          <a
            style={{ color: "white" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.perrier-jouet.com/it-it/"
          >
            <p className="footerPartner">Partner</p>
            <img className="footerLogo" src={IncalmoImgPartner} alt="" />
            <img className="footerLogo ml-4" src={IncalmoImgPartner2} alt="" />
          </a>
        </div>
      </div>
      <div className="footerContacts">
        <p>
          © INCÀLMO RISTORANTE <br />
          viale Rimembranze 1, 35042, Este - PD
          <br />
          0429 1761472 | info@incalmoristorante.com
          <br />
        </p>
        <p>
          <br />
          Ardimento s.r.l, Via Luigi Zoja, 30, 20153 MI <br />
          Partita I.V.A. 10141740968
        </p>
      </div>
      <div className="footerMenu">
        <div className="footerSocial">
          <a
            href="https://www.facebook.com/IncalmoRistorante"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://www.instagram.com/incalmoristorante/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <p>
          Shooting:{" "}
          <a
            style={{ textDecoration: "underline", color: "white" }}
            href="https://benedettabassanelli.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Benedetta Bassanelli
          </a>{" "}
          <br />
          <a
              href="https://www.hotel-beatrice.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
          >
            Privacy & Cookies Policy
          </a>
        </p>
      </div>
    </div>
  );
}
