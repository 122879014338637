import React from "react";
import "./squadra.css";
import LeftImg from "../../../assets/IMMAGINI/Squadra_Cucina.jpg";
import RightImg from "../../../assets/IMMAGINI/Squadra_Sala.jpg";
import {Helmet} from "react-helmet";

export default function Squadra() {
    return (
        <>
            <Helmet>
                <title>Squadra</title>
                <meta name="description" content="Incalmo squadra"/>
            </Helmet>
            <div className="squadraContainer">
                <div className="squadraLeftSide side">
                    <div className='d-flex flex-column text-left align-items-start'>
                        <h2 className='py-5 giorgio' style={{padding: "0"}}>CENE SEMPRE A 4 MANI</h2>
                        <p className="squadraText">
                            Mai come nella nostra cucina “Gli Opposti si Attraggono“: i nostri due Chef - Francesco
                            Massenz
                            e Leonardo Zanon - pur essendo entrambi Bellunesi, sono diversi sotto svariati aspetti ma
                            complementari e focalizzati su un obbiettivo solo: farvi sentire a vostro agio, perché
                            questa
                            non sia solo una cena, ma una bellissima esperienza.
                        </p>
                        <p className="squadraText">
                            Il primo esuberante, deciso e amante del mondo salato, il secondo più pacato ma sorprendente
                            come la sua pasticceria. Insieme danno vita alla cucina di Incàlmo, schietta, immediata,
                            riconoscibile e mai scontata.
                        </p>
                    </div>
                    <div>
                        <img className="squadraIMG" src={LeftImg} alt=""/>
                    </div>
                </div>
                <div className="squadraLeftSide side" style={{backgroundColor: '#6a8b82'}}>
                    <div>
                        <h2 className='py-5 giorgio' style={{padding: "0", color: '#fff'}}>PASSIONE ED ENTUSIASMO</h2>
                        <p className="squadraText">
                            A guidare la sala sono Michele Carretta e Ricardo Scacchetti - un milanese e un
                            italo-brasiliano
                            - titolari dell'attività e che hanno deciso di gestire in prima persona il delicato rapporto
                            tra
                            il cliente e la cucina.
                        </p>
                        <p className="squadraText pt-3">
                            Entrambi con una esperienza internazionale importante e una passione che ogni giorno si
                            trasforma nella cura del dettaglio e nel desiderio di far vivere un’ esperienza indelebile
                            ai
                            propri clienti.
                        </p>
                        <br/>
                    </div>
                    <div>
                        <img className="squadraIMG pt-2" src={RightImg} alt=""/>
                    </div>
                </div>

            </div>
        </>
    );
}
