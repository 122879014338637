import React, {useEffect} from 'react';
import Home from "./components/master/home/Home.js";
import Footer from "./components/master/footer/Footer.js";
import NavBar from "./components/master/nav/NavBar.js";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Menu from "./components/master/menu/Menu.js";
import Squadra from "./components/master/squadra/Squadra.js";
import Gallery from "./components/master/gallery/Gallery.js";
import Contatti from "./components/master/contatti/Contatti.js";
import Gift from "./components/master/gift/Gift.jsx";


function App() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <BrowserRouter>
            <div className="App">
                <NavBar/>
                <Routes>
                    <Route path="/" exact element={<Home/>}/>
                    <Route path="/menu" element={<Menu/>}/>
                    <Route path="/squadra" element={<Squadra/>}/>
                    <Route path="/gallery" element={<Gallery/>}/>
                    <Route path="/contatti" element={<Contatti/>}/>
                    <Route path="/gift" element={<Gift/>}/>
                </Routes>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;
