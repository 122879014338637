import React from "react";
import "./navbar.css";
import LogoGreen from "../../../assets/PNGLOGO/incalmo_marchio_verde.png";
import LogoBlack from "../../../assets/PNGLOGO/incalmo_marchio_nero.png";
import {Link} from "react-router-dom";

export default function NavBar() {
    const menuButtonAppear = () => {
        let logoGreen = document.getElementById("logoGreen");
        let menuButton = document.getElementById("menuButton");

        logoGreen.classList = "logoGreen disappear";
        menuButton.classList = "menuButton appear";
    };

    const menuButtonDisappear = () => {
        let logoGreen = document.getElementById("logoGreen");
        let menuButton = document.getElementById("menuButton");

        logoGreen.classList = "logoGreen appear";
        menuButton.classList = "menuButton disappear";
    };

    const openNavMenu = () => {
        let menuPanel = document.getElementById("navMenuContainer");
        menuPanel.style.display = "flex";
        menuPanel.classList = "navMenuContainer navAppear";
    };

    const closeNavMenu = () => {
        let menuPanel = document.getElementById("navMenuContainer");
        menuPanel.classList = "navMenuContainer navDisappear";
        setTimeout(() => {
            menuPanel.style.display = "none";
        }, 500);
    };

    return (
        <>
            <div className="navBar">
                <div className="navContainer">
                    <a
                        href="https://widget.thefork.com/d4c4c3c7-1feb-4542-80c3-e4a359d834a5" target="_blank" rel="noopener noreferrer">
                        <p className="prenota">PRENOTA</p>
                    </a>
                    <div className="logosContainer">
                        <img
                            id="logoGreen"
                            onMouseEnter={menuButtonAppear}
                            onMouseLeave={menuButtonDisappear}
                            onClick={openNavMenu}
                            className="logoGreen py-2 px-2"
                            src={LogoGreen}
                            alt=""
                        />
                        <img id="logoBlack" className="logoBlack py-2 px-2" src={LogoBlack} alt=""/>
                        <p id="menuButton" className="menuButton">
                            MENU
                        </p>
                    </div>
                    <div className="social">
                        <a
                            href="https://www.facebook.com/IncalmoRistorante"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="fab fa-facebook-f"/>
                        </a>
                        <a
                            href="https://www.instagram.com/incalmoristorante/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="fab fa-instagram"/>
                        </a>
                    </div>
                </div>
            </div>
            <div id="navMenuContainer" className="navMenuContainer">
                <button onClick={closeNavMenu}>
                    <i
                        style={{
                            fontSize: "55px",
                            color: "#6a8b82",
                        }}
                        className="fas fa-times"
                    />
                </button>
                <Link onClick={closeNavMenu} to="/">
                    Home
                </Link>
                <Link onClick={closeNavMenu} to="/squadra">
                    Squadra
                </Link>
                <Link onClick={closeNavMenu} to="/menu">
                    Menù
                </Link>
                <Link onClick={closeNavMenu} to="/gift">
                    Regala un'esperienza
                </Link>
                <Link onClick={closeNavMenu} to="/gallery">
                    Gallery
                </Link>
                <Link onClick={closeNavMenu} to="/contatti">
                    Contatti
                </Link>
                <a
                    href="https://widget.thefork.com/d4c4c3c7-1feb-4542-80c3-e4a359d834a5" target="_blank" rel="noopener noreferrer">
                    Prenota
                </a>
                <a href="https://www.hotel-beatrice.com/">Ospitalità</a>
            </div>
        </>
    );
}
