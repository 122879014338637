import React from 'react';
import "./contatti.css";
import {Helmet} from "react-helmet";

export default function Contatti() {
    return (
        <>
            <Helmet>
                <title>Contatti</title>
                <meta name="description" content="Incalmo squadra"/>
            </Helmet>
            <div className="contattiBody">
                <div className="contattiTextContainer">
                    <div className='px-3'>
                        <p className="contattiInfoText">
                            <strong className='giorgio con'>CONTATTI</strong> <br/> <br/>
                            0429 1761472 <br/>
                            info@incalmoristorante.com <br/>
                            viale Rimembranze 1, 35042, Este - PD
                        </p>
                        <br/>
                        <br/>
                        <p className="contattiInfoText">
                            <strong className='giorgio con'>ORARI</strong> <br/> <br/>
                            Lunedì: chiuso <br/>
                            Martedì a Sabato: dalle 19:30 alle 21:30 <br/>
                            Domenica: dalle 12.30 alle 14:30
                        </p>
                    </div>
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5824.812604312638!2d11.655770401748182!3d45.23027248885135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f1dfdf6467499%3A0xffaa083a0e63c8b9!2sViale%20Rimembranze%2C%201%2C%2035042%20Este%20PD!5e0!3m2!1sit!2sit!4v1602450030106!5m2!1sit!2sit"
                    width="100%"
                    height="700px"
                    frameBorder="0"
                    style={{border: "0"}}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    title="map"
                    id="contattiMap"
                />
            </div>
        </>
    );
}
